import React, { useState, useEffect } from "react";
import { Col, Button } from "react-bootstrap";
import Api from "../../services/api/api";
import { SubTitulo, ContainerConfirmacao } from "../../layout/fontes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./custom-button.css";
import {
  faCheckCircle,
  faExclamationTriangle,
  faCalendarAlt,
  faGlobeAmericas,
} from "@fortawesome/free-solid-svg-icons";

const FormConfirmacao = ({
  token,
  agendamento,
  cpf,
  confirmadoGlobal,
  changeTela,
  data_escolhida,
  reagendamento,
}) => {
  const [confirmado, setConfirmado] = useState(false);
  const [erro, setErro] = useState(false);
  const [loading_confir, setLoadingConfir] = useState(false);
  const [local, setLocal] = useState("Horário de Brasília");

  useEffect(() => {
    if (token) {
      Api.getLocal(token)
        .then((res) => {
          if (res && res.data) {
            setLocal(res.data.timezone);
          } else {
            console.log("Sem resposta da API");
          }
        })
        .catch((err) => console.error(err));
    }
  }, [token]);

  const onSubmit = (e) => {
    const data = {
      data_agendamento: agendamento.inicio,
      data_fim_agendamento: agendamento.fim,
      token: token,
      ...(cpf && { cpf: cpf }),
      ...(reagendamento && { agendamento: reagendamento }),
    };
    setErro(false);
    setLoadingConfir(true);

    if (reagendamento) {
      Api.Reagendar(data)
        .then((res) => {
          if (res) {
            confirmadoGlobal(true);
            setConfirmado(true);
          } else {
            setLoadingConfir(false);
          }
        })
        .catch((err) => {
          console.error(err);
          setErro(true);
          setLoadingConfir(false);
        });
    } else {
      Api.Agendar(data)
        .then((res) => {
          if (res) {
            confirmadoGlobal(true);
            setConfirmado(true);
          } else {
            setLoadingConfir(false);
          }
        })
        .catch((err) => {
          console.error(err);
          setErro(true);
          setLoadingConfir(false);
        });
    }
  };

  if (erro) {
    return (
      <Col className="ml-3 p-3" md={6} sm={12}>
        <ContainerConfirmacao>
          <SubTitulo className="text-warning">
            <FontAwesomeIcon icon={faExclamationTriangle} /> Ops... parece que
            este horário já está agendado, tente novamente com outro horário.
          </SubTitulo>
        </ContainerConfirmacao>
        <Button
          onClick={() => {
            changeTela(3);
          }}
          variant="outline-info"
        >
          Voltar{" "}
        </Button>{" "}
      </Col>
    );
  } else if (confirmado) {
    return (
      <Col className="ml-3 p-3" md={6} sm={12}>
        <SubTitulo className="text-success">
          <FontAwesomeIcon icon={faCalendarAlt} /> {data_escolhida}
        </SubTitulo>
        <p className="text-muted">
          <FontAwesomeIcon icon={faGlobeAmericas} /> {local}
        </p>
        <ContainerConfirmacao>
          <div style={{ display: "inline", marginTop: "20px" }}>
            <SubTitulo className="text-success">
              <FontAwesomeIcon icon={faCheckCircle} /> Agendamento Confirmado
              com sucesso
            </SubTitulo>
            <p className="text-muted">
              Foi enviado um e-mail com os detalhes do seu agendamento.
            </p>
          </div>
        </ContainerConfirmacao>
        <div className="text-right">
          <Button
            onClick={() => {
              changeTela(1);
            }}
            style={{ marginTop: "auto" }}
            variant="success"
          >
            Novo Agendamento
          </Button>
        </div>
      </Col>
    );
  } else {
    return (
      <Col className="ml-3 p-3" md={6} sm={12}>
        {data_escolhida && (
          <>
            <p style={{ color: "#8e00b2" }}>
              <FontAwesomeIcon icon={faCalendarAlt} /> {data_escolhida}
            </p>
            <p className="text-muted">
              <FontAwesomeIcon icon={faGlobeAmericas} /> {local}
            </p>
          </>
        )}
        <ContainerConfirmacao>
          <Button
            onClick={() => {
              changeTela(1);
            }}
            variant="outline-info"
            style={{
              marginTop: "auto",
              marginRight: "5px",
              marginLeft: "auto",
            }}
          >
            Voltar
          </Button>{" "}
          <Button
            disabled={loading_confir}
            onClick={onSubmit}
            style={{ marginTop: "auto" }}
            variant="info"
          >
            {loading_confir ? "Confirmando agendamento ..." : "Agendar"}
          </Button>
        </ContainerConfirmacao>
      </Col>
    );
  }
};

export default FormConfirmacao;
