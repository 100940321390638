import React from "react";
import { Row } from "react-bootstrap";
import SideBar from "./Sidebar";
import FormCpf from "./forms/form_cpf";
import FormPaciente from "./forms/form_paciente";
import FormConfirmacao from "./forms/form_confirmação";
import Calendario from "./Calendario";

const GeradorCard = ({
  tela,
  cpf,
  token,
  reagendamento,
  info_clinica,
  data_escolhida,
  agendamento,
  confirmado_global,
  changeCpf,
  changeTela,
  changeAgendamento,
  changeDataEscolhida,
  changeInfoClinica,
  confirmadoGlobal,
  camposObrigatorios,
  changeCamposObrigatorios,
  checks,
  changeChecks,
}) => {
  if (tela === 3) {
    return (
      <Row className="page" style={{ height: "80%" }}>
        <SideBar info_clinica={info_clinica} />
        <FormCpf
          changeTela={changeTela}
          changeCpf={changeCpf}
          token={token}
          changeCamposObrigatorios={changeCamposObrigatorios}
          changeChecks={changeChecks}
        />
      </Row>
    );
  } else if (tela === 2) {
    return (
      <Row className="page" style={{ height: "80%" }}>
        <SideBar info_clinica={info_clinica} />
        <FormPaciente
          changeTela={changeTela}
          cpf={cpf}
          token={token}
          camposObrigatorios={camposObrigatorios}
          checks={checks}
          changeChecks={changeChecks}
        />
      </Row>
    );
  } else if (tela === 1) {
    return (
      <Row key={tela} className="page" style={{ height: "80%" }}>
        <SideBar info_clinica={info_clinica} reagendamento={reagendamento} />
        <Calendario
          changeInfoClinica={changeInfoClinica}
          changeAgendamento={changeAgendamento}
          changeDataEscolhida={changeDataEscolhida}
          changeTela={changeTela}
          token={token}
          reagendamento={reagendamento}
        />
      </Row>
    );
  } else {
    return (
      <Row key={tela} className="page" style={{ height: "80%" }}>
        <SideBar
          info_clinica={info_clinica}
          changeDataEscolhida={changeDataEscolhida}
          changeTela={changeTela}
          confirmado_global={confirmado_global}
        />
        <FormConfirmacao
          changeTela={changeTela}
          cpf={cpf}
          agendamento={agendamento}
          token={token}
          data_escolhida={data_escolhida}
          confirmadoGlobal={confirmadoGlobal}
          reagendamento={reagendamento}
        />
      </Row>
    );
  }
};
export default GeradorCard;
