import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
const FooterStyle = styled.p`
  position: absolute;
  bottom: 0;
  left: 17%;
  font-size: 12px;
`;

const Footer = () => {
  return (
    <FooterStyle className="d-none d-sm-block">
      Feito com{" "}
      <FontAwesomeIcon color="#8E00B2" icon={faHeart}></FontAwesomeIcon> por{" "}
      <a
        href="https://www.gestaods.com.br"
        rel="noopener noreferrer"
        target="_blank"
      >
        GestãoDS - Software Médico
      </a>
    </FooterStyle>
  );
};
export default Footer;
