import React, { useEffect, useState, Fragment } from "react";
import { Col } from "react-bootstrap";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils from "react-day-picker/moment";
import moment from "moment";
import "moment/locale/pt-br";
import "./custom_calendar_style.css";
import { SubTitulo } from "../layout/fontes";
import AgendaApi from "../services/api/api";
import CalendarioSideBar from "./CalendarioSideBar";
import ReCAPTCHA from "react-google-recaptcha";

import Spinner from "../layout/spinner";
const Calendario = ({
  changeInfoClinica,
  changeDataEscolhida,
  changeAgendamento,
  changeTela,
  token,
  reagendamento,
}) => {
  const [loading, setLoading] = useState(false);
  const [tokenInvalido, setTokenInvalido] = useState(false);
  const [cat, setCat] = useState(false);
  const [loadingCalendario, setLoadingCalendario] = useState(false);
  const [dia_selecionado, setDiaSelecionado] = useState(null);
  const [dias, setDias] = useState([]);
  const [horarios, setHorarios] = useState([]);
  const [horariosSoma, setHorariosSoma] = useState("");
  const [mes, setMes] = useState("");
  const [calendario_side_bar, setCalendarioSideBar] = useState(false);
  const today = new Date().setHours(0,0,0,0)


  useEffect(() => {
    if (token) {
      getClinica(token);
      if (cat) {
        getData();
      }
    } else {
      setTokenInvalido(true);
    }
    //eslint-disable-next-line
  }, [cat]);

  const getData = (data = moment().format("DD/MM/YYYY")) => {
    setLoading(true);
    setMes(moment(data, "DD/MM/YYYY").toDate());

    AgendaApi.getAgenda(`${token}?data=${data}`)
      .then((res) => {
        if (res) {
          setDias(res.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const getClinica = (token) => {
    AgendaApi.BuscaClinica(`${token}/`)
      .then((res) => {
        if (res) {
          changeInfoClinica(res.data.data);
        }
      })
      .catch((err) => {
        console.error(err);
        setTokenInvalido(true);
      });
  };

  const comparadorArray = (date, array) => {
    if (array && array.length > 0) {
      let flag = false;
      array.forEach((data) => {
        if (data.data === date && data.disponivel) {
          flag = true;
          return;
        }
      });
      return flag;
    } else return false;
  };

  const getHorarios = (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }
    setDiaSelecionado(day);
    setCalendarioSideBar(true);
    const data_tratada = moment(day).format("DD/MM/YYYY");
    getHorariosRequest(data_tratada);
  };

  const getHorariosRequest = (data) => {
    setLoadingCalendario(true);
    AgendaApi.getHorarios(`${token}?data=${data}`)
      .then((res) => {
        if (res) {
          const horarios = res.data.data.map((x) => {
            return Object.assign(
              {},
              {
                horario: x.slice(0, -3),
                status: false,
              }
            );
          });
          setHorariosSoma(res.data.tempo_intervalo);
          setHorarios(horarios);
        }
        setLoadingCalendario(false);
      })
      .catch((err) => {
        setHorarios([]);
        setLoadingCalendario(false);
      });
  };

  const changeHorarioStatus = (horario) => {
    const novaListaHorarios = horarios.map((x) => {
      if (x.horario === horario) {
        x.status = true;
        return x;
      } else {
        x.status = false;
        return x;
      }
    });
    setHorarios(novaListaHorarios);
  };

  const confirmarHorario = (horario) => {
    const intervalo = moment(horario, "HH:mm")
      .add(horariosSoma, "minutes")
      .format("HH:mm");

    const string = `${horario} - ${intervalo}, ${moment(dia_selecionado).format(
      "dddd"
    )}, ${moment(dia_selecionado).format("DD")} de  ${moment(
      dia_selecionado
    ).format("MMMM")} de ${moment(dia_selecionado).format("YYYY")}
          `;

    changeAgendamento({
      inicio:
        moment(dia_selecionado).format("YYYY-MM-DD") + " " + horario + ":00",
      fim:
        moment(dia_selecionado).format("YYYY-MM-DD") + " " + intervalo + ":00",
    });
    changeDataEscolhida(string);

    changeTela(reagendamento ? 0 : 3);
  };

  return (
    <Fragment>
      {tokenInvalido ? (
        <Col sm={12} md={7} className="p-3 text-center">
          <img alt="Logo link incorreto" src="/linkIncorreto.png" />
          <p
            style={{ fontSize: "20px", color: "#676767", marginBottom: "3px" }}
          >
            Esta agenda online encontra-se indisponível
          </p>
          <p style={{ fontSize: "16px", color: "#676767" }}>
            Entre em contato com a clínica para mais informações
          </p>
        </Col>
      ) : (
        <Fragment>
          {!cat ? (
            <Col className="p-3 text-center" sm={12} md={7}>
              <img alt="Logo boas vindas" src="/bvindas.png" />
              <h4 style={{ color: "#6D2D90", marginTop: ".5rem" }}>
                Bem-vindo ao {reagendamento ? "Regendamento" : "Agendamento"}{" "}
                online
              </h4>
              <p style={{ color: "#656565" }}>
                Resolva o captcha abaixo para prosseguir
              </p>
              <ReCAPTCHA
                style={{ display: "flex", justifyContent: "center" }}
                sitekey="6Le3P-YUAAAAAKGbkKq4b82gBtQjckBLSxrydmqs"
                onErrored={() => {
                  setCat(false);
                }}
                onChange={() => {
                  setCat(true);
                }}
              />
            </Col>
          ) : (
            <Col className="ml-3 p-3" sm={12} md={calendario_side_bar ? 4 : 6}>
              <SubTitulo>Escolha uma data e horário</SubTitulo>
              {loading ? (
                <Spinner>
                  <DayPicker
                    disabled
                    month={mes}
                    localeUtils={MomentLocaleUtils}
                    locale={"pt-br"}
                  />
                </Spinner>
              ) : (
                <DayPicker
                  disabledDays={(date) => {
                    if (date < today) {
                      return true;
                    } else {
                      if (
                        comparadorArray(
                          moment(date).format("DD/MM/YYYY"),
                          dias.data
                        )
                      ) {
                        return false;
                      } else {
                        return true;
                      }
                    }
                  }}
                  selectedDays={dia_selecionado}
                  onMonthChange={(e) => {
                    getData(moment(e).format("DD/MM/YYYY"));
                  }}
                  onDayClick={getHorarios}
                  month={mes}
                  localeUtils={MomentLocaleUtils}
                  locale={"pt-br"}
                />
              )}
            </Col>
          )}
          {calendario_side_bar && (
            <CalendarioSideBar
              confirmarHorario={confirmarHorario}
              dia_selecionado={
                moment(dia_selecionado).format("dddd") +
                ", " +
                moment(dia_selecionado).format("DD") +
                " De " +
                moment(dia_selecionado).format("MMMM")
              }
              loadingCalendario={loadingCalendario}
              horarios={horarios}
              changeHorarioStatus={changeHorarioStatus}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};
export default Calendario;
