import React from "react";
const Spinner = ({ children, notSpinner }) => {
  return (
    <div className="spinner-container">
      {" "}
      {notSpinner ? "" : <div className="spinner"></div>}
      {children}
    </div>
  );
};
export default Spinner;
