import React, { useState, Fragment } from "react";
import { Field, Formik, Form } from "formik";
import {
  Row,
  Col,
  Button,
  FormControl,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faSpinner,
  faCheckSquare,
  faToggleOff,
  faToggleOn,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import Api from "../../services/api/api";
import * as Yup from "yup";
import { SubTitulo, Label, StyledCol, StyledDiv } from "../../layout/fontes";
import MaskedInput from "react-input-mask";
import moment from "moment";
import Select from "react-select";

Yup.setLocale({
  mixed: {
    default: "Inválido",
    required: "Campo obrigatório",
  },
});

const FormPaciente = ({
  token,
  cpf,
  changeTela,
  camposObrigatorios = [],
  checks,
  changeChecks,
}) => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const [loading, setLoading] = useState(false);
  const [validationRequired, setValidationRequired] = useState(false);
  const [estadoCivil, setEstadoCivil] = useState("");
  const [todosEmail, setTodosEmail] = useState(true);
  const [todosCelular, setTodosCelular] = useState(true);
  const formValues = {
    nomeCompleto: "",
    dataNascimento: "",
    email: "",
    celular: "",
    nome_conjuge: "",
    nome_pai: "",
    nome_mae: "",
    tipo_sangue: "",
    filhos: "",
    sexo: "",
    estado_civil: "",
    responsavel: "",
    telefone_responsavel: "",
    tipo_sanguineo: "",
    tem_filhos: "",
    cep: "",
    cidade: "",
    complemento: "",
    endereco: "",
    numero: "",
    uf: "",
  };

  const validacao = {
    nomeCompleto: Yup.string().test(
      "NomeCompleto",
      "Campo obrigatório",
      (value) => value && value.length > 2
    ),
    email: Yup.string().email().required(),
    ...(camposObrigatorios.includes("celular") && {
      celular: Yup.string().test(
        "Celular",
        "Informe um celular válido",
        (value) => value && value.replace(/\D/g, "").length > 9
      ),
    }),
    ...(camposObrigatorios.includes("rg") && {
      rg: Yup.string().test(
        "RG",
        "Informe um RG válido",
        (value) => value && value.replace(/\D/g, "").length > 8
      ),
    }),
    ...(camposObrigatorios.includes("nascimento") && {
      dataNascimento: Yup.string().required(),
    }),
    ...(camposObrigatorios.includes("nome_completo_mae") && {
      nome_completo_mae: Yup.string().test(
        "nome_completo_mae",
        "Campo obrigatório",
        (value) => value && value.length > 2
      ),
    }),
    ...(camposObrigatorios.includes("nome_completo_pai") && {
      nome_completo_pai: Yup.string().test(
        "nome_completo_pai",
        "Campo obrigatório",
        (value) => value && value.length > 2
      ),
    }),
    ...(camposObrigatorios.includes("nome_conjuge") &&
      estadoCivil !== "Divorciado(a)" &&
      estadoCivil !== "Separado(a)" &&
      estadoCivil !== "Solteiro(a)" &&
      estadoCivil !== "Viúvo(a)" && {
        nome_conjuge: Yup.string().required(),
      }),
    ...(camposObrigatorios.includes("telefone") && {
      telefone: Yup.string().test(
        "Telefone",
        "Informe um telefone válido",
        (value) => value && value.replace(/\D/g, "").length > 9
      ),
    }),
    ...(camposObrigatorios.includes("endereco") && {
      cep: Yup.string().required(),
      cidade: Yup.string().test(
        "cidade",
        "Campo obrigatório",
        (value) => value && value.length > 2
      ),
      bairro: Yup.string().test(
        "bairro",
        "Campo obrigatório",
        (value) => value && value.length > 2
      ),
      uf: Yup.string().test(
        "nome_completo_pai",
        "Campo obrigatório",
        (value) => value && value.length > 1
      ),
      endereco: Yup.string().test(
        "endereco",
        "Campo obrigatório",
        (value) => value && value.length > 2
      ),
      numero: Yup.string().required(),
    }),
    ...(camposObrigatorios.includes("responsavel") && {
      responsavel: Yup.string().test(
        "responsavel",
        "Campo obrigatório",
        (value) => value && value.length > 2
      ),
      telefone_responsavel: Yup.string().test(
        "telefone_responsavel",
        "Informe um contato válido",
        (value) => value && value.replace(/\D/g, "").length > 9
      ),
    }),
    ...(camposObrigatorios.includes("sexo") && {
      sexo: Yup.mixed().required(),
    }),
    ...(camposObrigatorios.includes("estado_civil") && {
      estado_civil: Yup.mixed().required(),
    }),
    ...(camposObrigatorios.includes("tem_filhos") && {
      tem_filhos: Yup.mixed().required(),
    }),
    ...(camposObrigatorios.includes("tipo_sanguineo") && {
      tipo_sanguineo: Yup.mixed().required(),
    }),
  };

  const handleCadastro = (e) => {
    setLoading(true);
    const data = {
      token,
      cpf,
      nome_completo: e.nomeCompleto,
      email: e.email,
      enviar_email_lembrete: checks.enviar_email_lembrete,
      enviar_email_marketing: checks.enviar_email_marketing,
      enviar_parabens_email: checks.enviar_parabens,
      ...(camposObrigatorios.includes("rg") && {
        rg: e.rg,
      }),
      ...(camposObrigatorios.includes("sexo") && {
        sexo: e.sexo.value,
      }),
      ...(camposObrigatorios.includes("celular") && {
        celular: e.celular.replace(/[^0-9.]+/g, ""),
        enviar_sms: checks.enviar_sms,
        enviar_whatsapp_lembrete: checks.enviar_whatsapp_lembrete,
        enviar_whatsapp_marketing: checks.enviar_whatsapp_marketing,
        enviar_parabens_whatsapp: checks.enviar_parabens_whatsapp,
      }),
      ...(camposObrigatorios.includes("nascimento") && {
        nascimento: moment(e.dataNascimento).format("DD/MM/YYYY"),
      }),
      ...(camposObrigatorios.includes("telefone") && {
        telefone: e.telefone,
      }),
      ...(camposObrigatorios.includes("endereco") && {
        cep: e.cep,
        cidade: e.cidade,
        bairro: e.bairro,
        uf: e.uf,
        endereco: e.endereco,
        numero: e.numero,
        complemento: e.complemento,
      }),
      ...(camposObrigatorios.includes("responsavel") && {
        responsavel: e.responsavel,
        telefone_responsavel: e.telefone_responsavel,
      }),
      ...(camposObrigatorios.includes("estado_civil") && {
        estado_civil: e.estado_civil.value,
      }),
      ...(camposObrigatorios.includes("tem_filhos") && {
        tem_filhos: e.tem_filhos.value,
      }),
      ...(camposObrigatorios.includes("tipo_sanguineo") && {
        tipo_sanguineo: e.tipo_sanguineo.value,
      }),
      ...(camposObrigatorios.includes("nome_completo_mae") && {
        nome_completo_mae: e.nome_completo_mae,
      }),
      ...(camposObrigatorios.includes("nome_completo_pai") && {
        nome_completo_pai: e.nome_completo_pai,
      }),
      ...(camposObrigatorios.includes("nome_conjuge") &&
        e.nome_conjuge.length > 3 && {
          nome_conjuge: e.nome_conjuge,
        }),
    };
    Api.PacienteCadastro(data)
      .then((res) => {
        if (res) {
          changeTela();
        }
      })
      .catch((err) => {        
        console.log(err.response);
      }).finally(() => setLoading(false));
  };

  const handleChecks = (state) => {
    let aux = checks;
    aux[state] = !aux[state];
    changeChecks({ ...aux });
  };

  const handleTodos = (status, email) => {
    let aux = checks;
    if (email) {
      if (status) {
        aux.enviar_email_lembrete = false;
        aux.enviar_email_marketing = false;
        aux.enviar_parabens = false;
      } else {
        aux.enviar_email_lembrete = true;
        aux.enviar_email_marketing = true;
        aux.enviar_parabens = true;
      }
      setTodosEmail(!todosEmail);
    } else {
      //celular
      if (status) {
        aux.enviar_sms = false;
        aux.enviar_whatsapp_lembrete = false;
        aux.enviar_whatsapp_marketing = false;
        aux.enviar_parabens_whatsapp = false;
      } else {
        aux.enviar_sms = true;
        aux.enviar_whatsapp_lembrete = true;
        aux.enviar_whatsapp_marketing = true;
        aux.enviar_parabens_whatsapp = true;
      }
      setTodosCelular(!todosCelular);
    }
    changeChecks({ ...aux });
  };

  const checkboxGenerator = (checked, label, state) => (
    <p style={p}>
      <FontAwesomeIcon
        icon={faCheckSquare}
        color={checked ? "#821FAB" : "#E7E7E7"}
        onClick={() => handleChecks(state)}
        style={{ cursor: "pointer", fontSize: "15px", marginBottom: "-1px" }}
      />
      {" " + label}
    </p>
  );

  const noPadding = {
    padding: isMobile ? "" : "0px",
  };

  const switches = {
    cursor: "pointer",
    fontSize: "20px",
    marginBottom: "-3px",
  };

  const p = {
    fontSize: "14px",
    color: "#5B5858",
    marginBottom: "2px",
  };

  return (
    <Col className="ml-3 p-3" md={7} sm={12}>
      <SubTitulo>Finalize o cadastro</SubTitulo>
      <Formik
        validateOnChange={validationRequired}
        validateOnBlur={false}
        initialValues={formValues}
        onSubmit={(values) => {
          setValidationRequired(false);
          handleCadastro(values);
        }}
        validationSchema={Yup.object().shape(validacao)}
      >
        {({ setFieldValue, values, errors }) => (
          <Form>
            <div
              style={{
                maxHeight: "60vh",
                overflow: "auto",
                overflowX: "hidden",
                padding: "0px 10px",
              }}
              id="customScroll"
            >
              <Row>
                {/* Dados obrigatórios */}
                <StyledCol md={12}>
                  <Label>Nome completo</Label>
                  <Field
                    name="nomeCompleto"
                    as={FormControl}
                    className={errors.nomeCompleto && "error"}
                  />
                  {errors.nomeCompleto && (
                    <StyledDiv>{errors.nomeCompleto}</StyledDiv>
                  )}
                </StyledCol>

                <StyledCol md={12}>
                  <Label>E-mail</Label>
                  <Field
                    name="email"
                    value={values.email}
                    as={FormControl}
                    className={errors.email && "error"}
                  />
                  {errors.email && (
                    <StyledDiv>Informe um e-mail válido</StyledDiv>
                  )}
                  <Row>
                    <Col md={4}>
                      <p style={p}>
                        <span> Selecionar todos</span>{" "}
                        {!isMobile && (
                          <OverlayTrigger
                            placement="right"
                            overlay={
                              <Tooltip id="tooltip">
                                <p>Controle das seguintes configurações:</p>
                                <p>
                                  - E-mails confirmando antecipadamente seu
                                  atendimento.
                                </p>
                                <p>
                                  - Emails com informações e novidades sobre
                                  atendimentos e sobre a clínica.
                                </p>
                                <p>
                                  - Emails com mensagens parabenizando-o pelo
                                  seu aniversário.
                                </p>
                              </Tooltip>
                            }
                          >
                            <span className="d-inline-block">
                              <FontAwesomeIcon
                                icon={faQuestionCircle}
                                color="#C4C4C4"
                                id="tooltip"
                              />
                            </span>
                          </OverlayTrigger>
                        )}{" "}
                        <FontAwesomeIcon
                          icon={todosEmail ? faToggleOn : faToggleOff}
                          color={todosEmail ? "#821FAB" : "#E7E7E7"}
                          onClick={() => handleTodos(todosEmail, true)}
                          style={switches}
                        />
                      </p>
                    </Col>
                    <Col md={4} style={noPadding}>
                      {checkboxGenerator(
                        checks.enviar_email_lembrete,
                        "Confirmação de atendimento",
                        "enviar_email_lembrete"
                      )}
                      {checkboxGenerator(
                        checks.enviar_parabens,
                        "Email de parabéns",
                        "enviar_parabens"
                      )}
                    </Col>
                    <Col md={4}>
                      {checkboxGenerator(
                        checks.enviar_email_marketing,
                        "Marketing da Clínica",
                        "enviar_email_marketing"
                      )}
                    </Col>
                  </Row>
                </StyledCol>
                {/* Dados obrigatórios */}

                {camposObrigatorios.includes("rg") && (
                  <StyledCol md={6} sm={12}>
                    <Label>RG</Label>
                    <Field
                      name="rg"
                      value={values.rg}
                      as={FormControl}
                      className={errors.rg && "error"}
                    />
                    {errors.rg && <StyledDiv>{errors.rg}</StyledDiv>}
                  </StyledCol>
                )}

                {camposObrigatorios.includes("sexo") && (
                  <StyledCol md={6} sm={12}>
                    <Label>Gênero</Label>
                    <Field
                      name="sexo"
                      as={Select}
                      options={[
                        { label: "Masculino", value: "M" },
                        { label: "Feminino", value: "F" },
                      ]}
                      onChange={(option) => {
                        setFieldValue("sexo", option);
                      }}
                      placeholder="Selecione"
                      classsName={errors.sexo && "error"}
                    ></Field>
                    {errors.sexo && <StyledDiv>{errors.sexo}</StyledDiv>}
                  </StyledCol>
                )}

                {camposObrigatorios.includes("celular") && (
                  <StyledCol md={12}>
                    <Label>Celular</Label>
                    <Field
                      name="celular"
                      value={values.celular}
                      as={MaskedInput}
                      mask={"(99) 999999999"}
                      className={
                        errors.celular ? "form-control" : "error form-control"
                      }
                    />
                    {errors.celular && <StyledDiv>{errors.celular}</StyledDiv>}
                    <Row>
                      <Col md={4}>
                        <p style={p}>
                          <span> Selecionar todos</span>{" "}
                          {!isMobile && (
                            <OverlayTrigger
                              placement="right"
                              overlay={
                                <Tooltip id="tooltip">
                                  <p>Controle das seguintes configurações:</p>
                                  <p>
                                    - Receber SMSs confirmando antecipadamente o
                                    seu atendimento.
                                  </p>
                                  <p>
                                    - Receber WhatsApp confirmando
                                    antecipadamente o seu atendimento.
                                  </p>
                                  <p>
                                    - Receber informações e novidades sobre
                                    atendimentos e sobre a clínica
                                  </p>
                                  <p>
                                    - Receber mensagens parabenizando-o pelo seu
                                    aniversário!
                                  </p>
                                </Tooltip>
                              }
                            >
                              <span className="d-inline-block">
                                <FontAwesomeIcon
                                  icon={faQuestionCircle}
                                  color="#C4C4C4"
                                  id="tooltip"
                                />
                              </span>
                            </OverlayTrigger>
                          )}{" "}
                          <FontAwesomeIcon
                            icon={todosCelular ? faToggleOn : faToggleOff}
                            color={todosCelular ? "#821FAB" : "#E7E7E7"}
                            onClick={() => handleTodos(todosCelular, false)}
                            style={switches}
                          />
                        </p>
                      </Col>
                      <Col md={4} style={noPadding}>
                        {checkboxGenerator(
                          checks.enviar_sms,
                          "SMS de Confirmação",
                          "enviar_sms"
                        )}
                        {checkboxGenerator(
                          checks.enviar_whatsapp_lembrete,
                          "Whatsapp de confirmação",
                          "enviar_whatsapp_lembrete"
                        )}
                      </Col>
                      <Col md={4}>
                        {checkboxGenerator(
                          checks.enviar_whatsapp_marketing,
                          "Whatsapp Marketing",
                          "enviar_whatsapp_marketing"
                        )}
                        {checkboxGenerator(
                          checks.enviar_parabens_whatsapp,
                          "Mensagens de Parabéns",
                          "enviar_parabens_whatsapp"
                        )}
                      </Col>
                    </Row>
                  </StyledCol>
                )}

                {camposObrigatorios.includes("telefone") && (
                  <StyledCol md={6}>
                    <Label>Telefone</Label>
                    <Field
                      name="telefone"
                      value={values.telefone}
                      as={MaskedInput}
                      mask={"(99) 999999999"}
                      className={
                        errors.celular ? "form-control" : "error form-control"
                      }
                    />
                    {errors.telefone && (
                      <StyledDiv>{errors.telefone}</StyledDiv>
                    )}
                  </StyledCol>
                )}

                {camposObrigatorios.includes("nascimento") && (
                  <StyledCol md={12}>
                    <Label>Data de Nascimento</Label>
                    <Field
                      name="dataNascimento"
                      as={FormControl}
                      type="date"
                      className={errors.dataNascimento && "error"}
                    />
                    {errors.dataNascimento && (
                      <StyledDiv>{errors.dataNascimento}</StyledDiv>
                    )}
                  </StyledCol>
                )}

                {camposObrigatorios.includes("nome_completo_mae") && (
                  <StyledCol md={12}>
                    <Label>Nome da mãe</Label>
                    <Field
                      name="nome_completo_mae"
                      as={FormControl}
                      className={errors.nome_completo_mae && "error"}
                    />
                    {errors.nome_completo_mae && (
                      <StyledDiv>{errors.nome_completo_mae}</StyledDiv>
                    )}
                  </StyledCol>
                )}

                {camposObrigatorios.includes("nome_completo_pai") && (
                  <StyledCol md={12}>
                    <Label>Nome do pai</Label>
                    <Field
                      name="nome_completo_pai"
                      as={FormControl}
                      className={errors.nome_completo_pai && "error"}
                    />
                    {errors.nome_completo_pai && (
                      <StyledDiv>{errors.nome_completo_pai}</StyledDiv>
                    )}
                  </StyledCol>
                )}

                {camposObrigatorios.includes("responsavel") && (
                  <Fragment>
                    <StyledCol md={12}>
                      <Label>Responsável</Label>
                      <Field
                        name="responsavel"
                        value={values.responsavel}
                        as={FormControl}
                        className={errors.responsavel && "error"}
                      />
                      {errors.responsavel && (
                        <StyledDiv>{errors.responsavel}</StyledDiv>
                      )}
                    </StyledCol>
                    <StyledCol md={6}>
                      <Label>Contato do responsável</Label>
                      <Field
                        name="telefone_responsavel"
                        value={values.telefone_responsavel}
                        as={MaskedInput}
                        mask={"(99) 999999999"}
                        className={
                          errors.celular ? "form-control" : "error form-control"
                        }
                      />
                      {errors.telefone_responsavel && (
                        <StyledDiv>{errors.telefone_responsavel}</StyledDiv>
                      )}
                    </StyledCol>
                  </Fragment>
                )}

                {camposObrigatorios.includes("estado_civil") && (
                  <StyledCol md={6}>
                    <Label>Estado civil</Label>
                    <Field
                      name="estado_civil"
                      as={Select}
                      options={[
                        { label: "Solteiro(a)", value: "Solteiro(a)" },
                        { label: "Casado(a)", value: "Casado(a)" },
                        { label: "Divorciado(a)", value: "Divorciado(a)" },
                        { label: "Separado(a)", value: "Separado(a)" },
                        { label: "Viúvo(a)", value: "Viúvo(a)" },
                        { label: "União Estável", value: "União Estável" },
                      ]}
                      onChange={(option) => {
                        setEstadoCivil(option.value);
                        setFieldValue("estado_civil", option);
                      }}
                      placeholder="Selecione"
                      classsName={errors.estado_civil && "error"}
                    ></Field>
                    {errors.estado_civil && (
                      <StyledDiv>{errors.estado_civil}</StyledDiv>
                    )}
                  </StyledCol>
                )}

                {camposObrigatorios.includes("nome_conjuge") &&
                  estadoCivil !== "Divorciado(a)" &&
                  estadoCivil !== "Separado(a)" &&
                  estadoCivil !== "Solteiro(a)" &&
                  estadoCivil !== "Viúvo(a)" && (
                    <StyledCol md={12}>
                      <Label>Nome do cônjuge</Label>
                      <Field
                        name="nome_conjuge"
                        as={FormControl}
                        className={errors.nome_conjuge && "error"}
                      />
                      {errors.nome_conjuge && (
                        <StyledDiv>{errors.nome_conjuge}</StyledDiv>
                      )}
                    </StyledCol>
                  )}

                {camposObrigatorios.includes("tipo_sanguineo") && (
                  <StyledCol md={6}>
                    <Label>Tipo sanguíneo</Label>
                    <Field
                      name="tipo_sanguineo"
                      as={Select}
                      options={[
                        { label: "O+", value: "O+" },
                        { label: "A+", value: "A+" },
                        { label: "B+", value: "B+" },
                        { label: "O-", value: "O-" },
                        { label: "A-", value: "A-" },
                        { label: "B-", value: "B-" },
                        { label: "AB+", value: "AB+" },
                        { label: "AB-", value: "AB-" },
                      ]}
                      onChange={(option) => {
                        setFieldValue("tipo_sanguineo", option);
                      }}
                      placeholder="Selecione"
                      classsName={errors.tipo_sanguineo && "error"}
                    ></Field>
                    {errors.tipo_sanguineo && (
                      <StyledDiv>{errors.tipo_sanguineo}</StyledDiv>
                    )}
                  </StyledCol>
                )}

                {camposObrigatorios.includes("tem_filhos") && (
                  <StyledCol md={6}>
                    <Label>Tem filhos(s)</Label>
                    <Field
                      name="tem_filhos"
                      as={Select}
                      options={[
                        { label: "Sim", value: true },
                        { label: "Não", value: false },
                      ]}
                      onChange={(option) => {
                        setFieldValue("tem_filhos", option);
                      }}
                      placeholder="Selecione"
                      classsName={errors.tem_filhos && "error"}
                    ></Field>
                    {errors.tem_filhos && (
                      <StyledDiv>{errors.tem_filhos}</StyledDiv>
                    )}
                  </StyledCol>
                )}

                {camposObrigatorios.includes("endereco") && (
                  <Fragment>
                    <StyledCol md={12}>
                      <Label>CEP</Label>
                      <Field
                        name="cep"
                        value={values.cep}
                        as={MaskedInput}
                        mask={"99999-999"}
                        className={
                          errors.celular ? "form-control" : "error form-control"
                        }
                        onChange={(e) => {
                          setFieldValue("cep", e.target.value);
                          if (e.target.value.replace(/\D/g, "").length === 8) {
                            Api.getCep(e.target.value).then((cep) => {
                              setFieldValue("endereco", cep.data.logradouro);
                              setFieldValue("bairro", cep.data.bairro);
                              setFieldValue("cidade", cep.data.localidade);
                              setFieldValue("uf", cep.data.uf);
                            });
                          }
                        }}
                      />
                      {errors.cep && <StyledDiv>{errors.cep}</StyledDiv>}
                    </StyledCol>

                    <StyledCol md={12}>
                      <Label>UF</Label>
                      <Field
                        name="uf"
                        value={values.uf}
                        as={FormControl}
                        className={errors.uf && "error"}
                      />
                      {errors.uf && <StyledDiv>{errors.uf}</StyledDiv>}
                    </StyledCol>

                    <StyledCol md={12}>
                      <Label>Cidade</Label>
                      <Field
                        name="cidade"
                        value={values.cidade}
                        as={FormControl}
                        className={errors.cidade && "error"}
                      />
                      {errors.cidade && <StyledDiv>{errors.cidade}</StyledDiv>}
                    </StyledCol>

                    <StyledCol md={12}>
                      <Label>Bairro</Label>
                      <Field
                        name="bairro"
                        value={values.bairro}
                        as={FormControl}
                        className={errors.bairro && "error"}
                      />
                      {errors.bairro && <StyledDiv>{errors.bairro}</StyledDiv>}
                    </StyledCol>

                    <StyledCol md={12}>
                      <Label>Endereço</Label>
                      <Field
                        name="endereco"
                        value={values.endereco}
                        as={FormControl}
                        className={errors.endereco && "error"}
                      />
                      {errors.endereco && (
                        <StyledDiv>{errors.endereco}</StyledDiv>
                      )}
                    </StyledCol>

                    <StyledCol md={12}>
                      <Label>Número</Label>
                      <Field
                        name="numero"
                        value={values.numero}
                        as={FormControl}
                        className={errors.numero && "error"}
                      />
                      {errors.numero && <StyledDiv>{errors.numero}</StyledDiv>}
                    </StyledCol>

                    <StyledCol md={12}>
                      <Label>Complemento</Label>
                      <Field
                        name="complemento"
                        value={values.complemento}
                        as={FormControl}
                        className={errors.complemento && "error"}
                      />
                      {errors.complemento && (
                        <StyledDiv>{errors.complemento}</StyledDiv>
                      )}
                    </StyledCol>
                  </Fragment>
                )}
              </Row>
            </div>
            <hr />
            <div className="text-right">
              <Button
                style={{ marginRight: "5px" }}
                onClick={() => changeTela(1)}
                variant="outline-info"
              >
                Voltar
              </Button>
              <Button
                disabled={loading}
                type="submit"
                variant="info"
                onClick={() => setValidationRequired(true)}
              >
                Continuar{" "}
                {loading ? (
                  <FontAwesomeIcon color="white" icon={faSpinner} />
                ) : (
                  <FontAwesomeIcon color="white" icon={faChevronRight} />
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Col>
  );
};

export default FormPaciente;
