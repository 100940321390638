import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { ContextConsumer } from "../services/contextapi";
import GeradorCard from "./GeradorCard";

const Content = () => {
  return (
    <ContextConsumer>
      {({
        tela,
        cpf,
        token,
        reagendamento,
        info_clinica,
        data_escolhida,
        agendamento,
        confirmado_global,
        changeCpf,
        changeTela,
        changeAgendamento,
        changeDataEscolhida,
        changeInfoClinica,
        confirmadoGlobal,
        camposObrigatorios,
        changeCamposObrigatorios,
        checks,
        changeChecks,
      }) => (
        <Container className="h-100">
          <Row className="h-100 justify-content-center align-items-center">
            <Col md={12}>
              <Card className="card-block shadow-sm rounded">
                <GeradorCard
                  changeTela={changeTela}
                  changeCpf={changeCpf}
                  cpf={cpf}
                  token={token}
                  reagendamento={reagendamento}
                  tela={tela}
                  info_clinica={info_clinica}
                  data_escolhida={data_escolhida}
                  confirmado_global={confirmado_global}
                  agendamento={agendamento}
                  changeDataEscolhida={changeDataEscolhida}
                  changeAgendamento={changeAgendamento}
                  changeInfoClinica={changeInfoClinica}
                  confirmadoGlobal={confirmadoGlobal}
                  camposObrigatorios={camposObrigatorios}
                  changeCamposObrigatorios={changeCamposObrigatorios}
                  checks={checks}
                  changeChecks={changeChecks}
                />
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </ContextConsumer>
  );
};
export default Content;
