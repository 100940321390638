import SERVER from "./server_url";
import axios from "axios";

const url = window.location.href;
const ENV =
  url.includes("devcalendario") || url.includes("localhost") ? "dev-" : "";
const SERVER_URL_BUSCA_CLINICA_INFO = `${SERVER}/api/${ENV}dados-agendamento/`;
const SERVER_URL_BUSCA_PACIENTE = `${SERVER}/api/${ENV}paciente/`;
const SERVER_URL_POST_PACIENTE = `${SERVER}/api/${ENV}paciente/cadastrar/`;
const SERVER_URL_GET_AGENDA = `${SERVER}/api/${ENV}agendamento/dias-disponiveis/`;
const SERVER_URL_GET_HORARIOS = `${SERVER}/api/${ENV}agendamento/horarios-disponiveis/`;
const SERVER_URL_POST_AGENDAMENTO = `${SERVER}/api/${ENV}agendamento/agendar/`;
const SERVER_URL_PUT_AGENDAMENTO = `${SERVER}/api/${ENV}agendamento/reagendar/`;
const SERVER_URL_GET_LOCAL = `${SERVER}/api/${ENV}agendamento/retornar-fuso-horario/`;

export default class Api {
  static BuscaClinica(params) {
    return axios(SERVER_URL_BUSCA_CLINICA_INFO + params, {
      method: "get",
    });
  }
  static BuscaPaciente(params) {
    return axios(SERVER_URL_BUSCA_PACIENTE + params, {
      method: "get",
    });
  }
  static PacienteCadastro(data) {
    return axios(SERVER_URL_POST_PACIENTE, {
      method: "post",
      data: data,
    });
  }
  static Agendar(data) {
    return axios(SERVER_URL_POST_AGENDAMENTO, {
      method: "post",
      data: data,
    });
  }
  static Reagendar(data) {
    return axios(SERVER_URL_PUT_AGENDAMENTO, {
      method: "put",
      data: data,
    });
  }
  static getAgenda(params) {
    return axios(SERVER_URL_GET_AGENDA + params, {
      method: "get",
    });
  }
  static getHorarios(params) {
    return axios(SERVER_URL_GET_HORARIOS + params, {
      method: "get",
    });
  }
  static getLocal(token) {
    return axios(SERVER_URL_GET_LOCAL + token, {
      method: "get",
    });
  }
  static getCep(cep) {
    return axios(`https://viacep.com.br/ws/${cep}/json/unicode/`, {
      method: "get",
      sucessHandle: false,
      withCredentials: false,
    });
  }
}
