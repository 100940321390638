import React from "react";
import Container from "./component/Container";
import { ContextProvider } from "./services/contextapi";

function App() {
  return (
    <ContextProvider>
      <Container />
    </ContextProvider>
  );
}

export default App;
