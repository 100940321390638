import React from "react";
import { Button, Col } from "react-bootstrap";
import { Titulo, ContainerInfoClinica } from "../layout/fontes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faPhoneAlt,
  faStethoscope,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons"
import Footer from "./footer"
const SideBar = ({
  data_escolhida,
  changeTela,
  changeDataEscolhida,
  confirmado_global,
  info_clinica,
  reagendamento,
}) => {
  return (
    <Col className="ml-3 p-3 border-right" md={4} sm={12}>
      {data_escolhida && !confirmado_global ? (
        <Button
          style={{
            borderRadius: "50%",
          }}
          variant="light"
          onClick={() => {
            changeDataEscolhida("");
            changeTela(3);
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
      ) : (
        ""
      )}
      <a
        href="https://www.gestaods.com.br"
        target="no_blank"
        rel="noreferrer noopener"
      >
        <img
          alt="logo gestão ds"
          style={{
            width: "48px",
            height: "48px",
            position: "absolute",
            left: "80%",
            top: "2%",
          }}
          src="/logo.png"
        />
      </a>
      <Titulo>{reagendamento ? "Reagendamento" : "Agendamento"}</Titulo>

      {info_clinica && (
        <ContainerInfoClinica>
          <p
            style={{
              color: "#878787",
              fontSize: "18px",
              fontWeight: "bold",
              marginLeft: "24px",
              marginTop: "40px",
            }}
          >
            {info_clinica.clinica}
          </p>
          <p className="text-muted">
            <FontAwesomeIcon
              style={{ color: "#acacac", marginRight: "10px" }}
              icon={faPhoneAlt}
            />
            {info_clinica.clinica_fone}
          </p>

          <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
            <FontAwesomeIcon
              style={{
                color: '#acacac',
                transform: 'scale(1.2)',
              }}
              icon={faMapMarkerAlt}
            />
            <p className='text-muted' style={{ margin: 0 }}>
              {info_clinica?.clinica_endereco}
            </p>
          </div>
          <p
            style={{
              color: "#878787",
              fontSize: "18px",
              fontWeight: "bold",
              marginTop: "40px",
            }}
          >
            <FontAwesomeIcon
              style={{ color: "#acacac", marginRight: "10px" }}
              icon={faStethoscope}
            />
            {info_clinica.medico}
          </p>
          {info_clinica.crm && (
            <p style={{ marginLeft: "24px" }} className="text-muted">
              CRM: {info_clinica.crm}
            </p>
          )}
          {info_clinica.especialidade && (
            <p
              style={{ marginLeft: "24px", marginBottom: "40px" }}
              className="text-muted"
            >
              {info_clinica.especialidade}
            </p>
          )}
        </ContainerInfoClinica>
      )}
      <Footer />
    </Col>
  );
};
export default SideBar;
