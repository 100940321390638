import { Col } from "react-bootstrap";
import styled from "styled-components";

const Titulo = styled.h1`
    font-size: 28px;
    font-weight: 600;
    line-height: 32px;
    color: #8e00b2;
}`;

const SubTitulo = styled.h2`
    font-size: 16px;
    font-weight: 600;
    line-height: 32px;
    color: #505358;
}`;

const SubTituloCalendario = styled.h4`
    margin-top: 60px;
    font-size: 16px;
    font-weight: 200;
    line-height: 16px;
    color: #505358;
}`;

const StyledCol = styled(Col)`
  margin-bottom: 3%;
`;

const StyledDiv = styled.div`
  color: red;
  font-size: 13px;
`;

const ContainerHorarios = styled.div`
    overflow-y: auto;
    height: 300px;
    padding-top: 8px;
    padding-right: 20px;
}`;

const ContainerForm = styled.div`
    overflow-y: auto;
    height: 300px;
    padding: 10px;
    padding-right: 20px;
}`;

const ContainerConfirmacao = styled.div`
    display: flex;
    height: 200px;
}`;

const Label = styled.label`
    font-size: 16px;
    font-weight: 300;
    line-height: 8px;
    color: #505358;
    padding-bottom: 2px;
}`;

const ContainerInfoClinica = styled.div`
  margin-top: 10%;
  margin-bottom: 15%;
`;

export {
  Titulo,
  SubTitulo,
  SubTituloCalendario,
  Label,
  ContainerHorarios,
  ContainerForm,
  ContainerConfirmacao,
  ContainerInfoClinica,
  StyledCol,
  StyledDiv,
};
