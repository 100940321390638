import React from "react";
import styled from "styled-components";

const Botao = styled.button`
    width:100%;
    padding:5px;
    background-color:white;
    border: 2px solid #8E00B2;
    margin-bottom:4px;
    font-size: 12px;
    font-weight: 600;
    line-height: 32px;
    color:#8E00B2;
    border-radius:5px;
    &:hover {
        color: #653D8C;
        border: 2px solid #653D8C;
        outline-offset: 2px;
        outline: none;
      }
}`;

const BotaoConfirmar = styled.button`
    width:50%;
    padding:5px;
    margin-left:4px;
    background-color: #8E00B2;
    border: 1px solid #8E00B2;
    margin-bottom:4px;
    font-size: 12px;
    font-weight: 600;
    line-height: 32px;
    color:white;
    border-radius:5px;
    &:hover {
        background: #653D8C;
        outline-offset: 2px;
        outline: none;
      }
}`;

const BotaoDisable = styled.button`
    width: 50%;
    padding: 5px;
    margin-left: 4px;
    background: #BABABA;
    border: 2px solid #BABABA;
    margin-bottom: 4px;
    font-size: 12px;
    font-weight: 600;
    line-height: 32px;
    color: white;
    border-radius: 5px;
}`;

const ContentBotoes = styled.div`
  display: flex;
`;

const BotaoCalendario = ({
  horario,
  status,
  changeHorarioStatus,
  confirmarHorario,
  disabled,
}) => {
  if (disabled) {
    return <Botao>--:--</Botao>;
  } else if (!status) {
    return (
      <Botao
        onClick={() => {
          changeHorarioStatus(horario);
        }}
      >
        {horario}
      </Botao>
    );
  } else {
    return (
      <ContentBotoes id="content-botoes-calendar">
        <BotaoDisable disabled>{horario}</BotaoDisable>
        <BotaoConfirmar
          onClick={() => {
            confirmarHorario(horario);
          }}
        >
          Selecionar
        </BotaoConfirmar>
      </ContentBotoes>
    );
  }
};
export default BotaoCalendario;
