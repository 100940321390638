import React, { useState } from 'react'
import { Form, Col, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Api from '../../services/api/api'
import MaskedInput from 'react-text-mask'
import { Label, SubTitulo } from '../../layout/fontes'
import { Fragment } from 'react'
import { validateCpf } from '../../helpers'

const FormCpf = ({
  token,
  changeCpf,
  changeTela,
  changeCamposObrigatorios,
  changeChecks,
}) => {
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState(false)
  const [cpf, setCpf] = useState('')

  const onSubmit = (e) => {
    e.preventDefault()
    if (!cpf) {
      setErrors(true)
      return
    }

    if (cpf && !validateCpf(cpf)) {
      setErrors({ message: 'CPF inválido' })
      return false
    }

    const cpfTratado = cpf.replace(/\.|-/g, '')
    if (cpfTratado.length !== 11) {
      setErrors(true)
      return
    }
    setLoading(true)

    Api.BuscaPaciente(`${token}/${cpfTratado}/`)
      .then((res) => {
        if (res) {
          setErrors(false)
          setLoading(false)
          changeCpf(cpfTratado)
          changeTela()
        }
      })
      .catch((err) => {
        setLoading(false)
        changeCamposObrigatorios(
          err.response.data ? err.response.data.campos_obrigatorios : []
        )
        changeCpf(cpfTratado)
        changeTela(2)
      })
  }

  return (
    <Col className='ml-3 p-3' style={{ minHeight: '420px' }} md={6} sm={12}>
      <SubTitulo>Preencha o campo</SubTitulo>
      <Form onSubmit={onSubmit}>
        <Label>CPF *</Label>
        <MaskedInput
          mask={[
            /\d/,
            /\d/,
            /\d/,
            '.',
            /\d/,
            /\d/,
            /\d/,
            '.',
            /\d/,
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/,
          ]}
          value={cpf}
          onChange={(e) => setCpf(e.target.value)}
          guide={false}
          className='form-control'
          name='cpf'
        />
        {errors && (
          <p style={{ marginTop: '8px' }} className='text-danger'>
            {errors?.message ? errors.message : 'Campo obrigatório *'}
          </p>
        )}
        <br />
        <div className='text-right'>
          {loading ? (
            <Button disabled type='submit' variant='info'>
              Carregando ...{' '}
            </Button>
          ) : (
            <Fragment>
              <Button
                onClick={() => changeTela(1)}
                type='submit'
                variant='outline-info'
              >
                Voltar
              </Button>{' '}
              <Button type='submit' variant='info'>
                Continuar{' '}
                <FontAwesomeIcon color='white' icon={faChevronRight} />
              </Button>
            </Fragment>
          )}
        </div>
      </Form>
    </Col>
  )
}

export default FormCpf
