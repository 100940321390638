import React from "react";
import { Col } from "react-bootstrap";
import { SubTituloCalendario, ContainerHorarios } from "../layout/fontes";
import ButtonCalendar from "../layout/button_calendar";
import Spinner from "../layout/spinner";

const HorariosCalendario = ({
  dia_selecionado,
  loadingCalendario,
  horarios,
  changeHorarioStatus,
  confirmarHorario,
}) => {
  return (
    <Col className="ml-3 p-3" md={3} sm={12}>
      <SubTituloCalendario>{dia_selecionado}</SubTituloCalendario>
      {loadingCalendario ? (
        <Spinner notSpinner>
          <ContainerHorarios id="customScroll">
            <ButtonCalendar disabled />
            <ButtonCalendar disabled />
            <ButtonCalendar disabled />
            <ButtonCalendar disabled />
            <ButtonCalendar disabled />
            <ButtonCalendar disabled />
            <ButtonCalendar disabled />
          </ContainerHorarios>
        </Spinner>
      ) : (
        <ContainerHorarios id="customScroll">
          {horarios &&
            horarios.map((horario, index) => (
              <ButtonCalendar
                confirmarHorario={confirmarHorario}
                changeHorarioStatus={changeHorarioStatus}
                status={horario.status}
                key={index}
                horario={horario.horario}
              />
            ))}
        </ContainerHorarios>
      )}
    </Col>
  );
};
export default HorariosCalendario;
