import React, { useState } from "react";

const RotaContext = React.createContext();
const ContextProvider = ({ children }) => {
  const url = window.location.search.split("token=")[1];
  let token = url;
  let reagendamento = "";

  if (url && url.includes("&agendamento=")) {
    token = url.split("&agendamento=")[0];
    reagendamento = url.split("&agendamento=")[1];
  }

  const [tela, setTela] = useState(1);
  const [cpf, setCpf] = useState("");
  const [agendamento, setAgendamento] = useState({
    inicio: "",
    fim: "",
  });
  const [info_clinica, setInfoClinica] = useState(null);
  const [data_escolhida, setDataEscolhida] = useState("");
  const [camposObrigatorios, setCamposObrigatorios] = useState([]);
  const [confirmado_global, setConfirmadoGlobal] = useState(false);
  const [checks, setChecks] = useState({
    enviar_email_lembrete: true,
    enviar_email_marketing: true,
    enviar_parabens: true,
    enviar_sms: true,
    enviar_whatsapp_lembrete: true,
    enviar_whatsapp_marketing: true,
    enviar_parabens_whatsapp: true,
  });

  return (
    <RotaContext.Provider
      value={{
        tela,
        cpf,
        token,
        reagendamento,
        data_escolhida,
        agendamento,
        confirmado_global,
        info_clinica,
        changeInfoClinica: setInfoClinica,
        changeTela: setTela,
        changeCpf: setCpf,
        changeAgendamento: setAgendamento,
        changeDataEscolhida: setDataEscolhida,
        confirmadoGlobal: setConfirmadoGlobal,
        camposObrigatorios: camposObrigatorios,
        changeCamposObrigatorios: setCamposObrigatorios,
        checks: checks,
        changeChecks: setChecks,
      }}
    >
      {children}
    </RotaContext.Provider>
  );
};

const ContextConsumer = RotaContext.Consumer;
export { ContextProvider, ContextConsumer };
